import React, {useState} from 'react';
import './App.css'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Home from './pages/home'
import { BrowserRouter } from 'react-router-dom';

function App() {
  const [switchMode, setSwitchMode] = useState((!localStorage.storageMode ? "dark" : localStorage.storageMode))
  const darkTheme = createTheme({
    palette: {
      mode: switchMode,
    },
  });
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <BrowserRouter>
        <Home setSwitchMode={setSwitchMode} switchMode={switchMode} />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
