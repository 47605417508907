import React, {useRef, useState} from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import data from '../data.json'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from "@mui/material";

function createData(Method, Total, Paid, Remaining) {
  return { Method, Total, Paid, Remaining };
}

let rows = [];

export default function Client(){
    const clientInput = useRef()
    const [inputValue, setInputValue] = useState("");
    function inputChange(){
        setInputValue(clientInput.current.value)
        rows = []
        if(clientInput.current.value !== ""){
            Object.values(data[clientInput.current.value]).map((cd)=>{
                rows.push(createData(cd["method"], (typeof(cd["total"]) !== "string" ? "AED " + Number(cd["total"]).toLocaleString('en') : cd["total"]), (typeof(cd["paid"]) !== "string" ? "AED " + Number(cd["paid"]).toLocaleString('en') : cd["paid"]), (typeof(cd["remaining"]) !== "string" ? "AED " + Number(cd["remaining"]).toLocaleString('en') : cd["remaining"])))
            })
        }
    }
    return(
        <div>
            <Autocomplete
                disablePortal
                options={Object.keys(data)}
                sx={{ width: 300, margin:'auto' }}
                onBlur={inputChange}
                renderInput={(params) => <TextField inputRef={clientInput} {...params} label="Client" />}
            />
            {(inputValue !== "" ?
            <TableContainer component={Paper} sx={{width:'800px', margin:'auto', marginTop:5}}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontWeight:'bold'}}>Payment Method</TableCell>
                            <TableCell sx={{fontWeight:'bold'}} align="right">Total</TableCell>
                            <TableCell sx={{fontWeight:'bold'}} align="right">Paid</TableCell>
                            <TableCell sx={{fontWeight:'bold'}} align="right">Remaining</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            hover
                            key={row.Method}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row">
                                {row.Method}
                            </TableCell>
                            <TableCell align="right">{row.Total}</TableCell>
                            <TableCell align="right">{row.Paid}</TableCell>
                            <TableCell align="right">{row.Remaining}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer> : null
            )}
        </div>
    )
}